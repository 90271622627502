<template>
  <section class="section bg-secondary">
    <div class="container">
      <div class="row justify-content-center text-center mb-md">
        <div class="col-md-4">
          <img src="/img/common/optimized/About_Ayun.svg" class="image" />
        </div>
        <div class="col-md-8">
          <h2 class="display-3 after">About Ayun!</h2>
          <p>
            Ayun! is that "comforting-feeling" Filipino word that we exclaim
            when we find something lost after a seemingly futile attempt at
            finding it. This is what we want our users to feel every time
            they log in—happy and less stressed. Focus on your daily routine, and
            leave the monitoring of all your RPE to us.
          </p>
          <p>
            Ayun! is an intuitive asset monitoring system that utilizes QR Codes
            and CLOUD TECHNOLOGY to transfer and track the accountability of RPE
            within your supply chain.
          </p>
        </div>
      </div>
      <!-- <div class="row justify-content-center">
        <div class="col-md-8">
          <video id="video" class="video" width="100%" controls>
            <source src="video/about-us-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "about-us",
  data() {
    return {
      isAutoPlay: false,
      vidEl: null,
    };
  },
  computed: mapGetters("scroll", ["isVideoPlaying"]),
  watch: {
    isVideoPlaying(value) {
      if (value) {
        this.vidEl.play();
      } else {
        this.vidEl.pause();
      }
    },
  },
  mounted() {
    this.vidEl = document.getElementById("video");
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-weight: bolder;
  font-size: 40px;
  position: relative;
  padding: 10px 0 10px;
  margin: 0 0 50px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 160px;
    width: 90%;
    height: 2px;
    background: #f18f01;
  }
}
p {
  text-align: justify;
  font-size: 16px;
  margin-top: -20px;
}
.image {
  width: 100%;
  padding: 55px 15px 60px;
  margin-top: -20px;
}
@media (max-width: 768px) {
  h2 {
    font-size: 25px;
  }
  p {
    margin: 20px;
    font-size: 14px;
  }
  .image {
    width: 50%;
    margin-bottom: -70px;
  }
}
@media (max-width: 990px) {
  h2 {
    font-size: 30px;
  }
  .image {
    width: 250px;
    margin-top: 20px;
  }
}
</style>
