<template>
  <section class="section section-lg section-shaped my-0">
    <div class="container">
      <div class="row justify-content-center text-center mb-sm">
        <h3 class="mb-4">
          <b> Which Ayun! is best for your company?</b>
        </h3>
        <img
          src="../../assets/images/optimized/ayun-ab-and-qb-comparison.png"
          width="90%"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "app-comparison",
};
</script>

<style lang="scss" scoped>
.section {
  background-color: white;
  h3 {
    color: #122b91;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 25px;
  }
}
</style>
