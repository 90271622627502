<template>
  <section class="section section-ayun">
    <div class="container">
      <div class="panel">
        <div class="row justify-content-center text-center mb-sm">
          <div class="col-lg-12">
            <h3 class="display-3 get-in-touch">Get In Touch</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <loading
              :active.sync="isLoading"
              loader="spinner"
              color="#20A8D8"
              :is-full-page="false"
            />
            <h6 class="mb-1">Leave us a message</h6>
            <ValidationObserver ref="form">
              <ValidationProvider
                name="Company Name"
                rules="required"
                v-slot="{ errors }"
              >
                <base-input
                  class="mt-4"
                  alternative
                  placeholder="Company"
                  v-model="form.companyName"
                  :error="errors[0]"
                />
              </ValidationProvider>

              <ValidationProvider
                name="Full Name"
                rules="required"
                v-slot="{ errors }"
              >
                <base-input
                  alternative
                  placeholder="Full Name"
                  v-model="form.fullName"
                  :error="errors[0]"
                />
              </ValidationProvider>

              <ValidationProvider
                name="Contact No"
                rules="required"
                v-slot="{ errors }"
              >
                <base-input
                  alternative
                  placeholder="Contact No"
                  v-model="form.phoneNumber"
                  :error="errors[0]"
                />
              </ValidationProvider>

              <base-input
                alternative
                placeholder="Email Address"
                v-model="form.email"
              />

              <base-input class="mb-4">
                <ValidationProvider
                  name="Message"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <textarea
                    class="form-control form-control-alternative"
                    name="name"
                    rows="4"
                    cols="80"
                    placeholder="Type a message..."
                    v-model="form.message"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </base-input>
            </ValidationObserver>
            <base-button
              type="elog-primary"
              round
              block
              size="lg"
              @click="onSubmit"
              >Send Message</base-button
            >
          </div>
          <div class="col-md-6 mb-3 mt-5">
            <div class="pr-md-5">
              <ul class="list-unstyled">
                <li class="listed-info">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-map-marker icons"></i>
                    <h6 class="mb-0">
                      Pulo-Diezmo Road, Cabuyao Laguna, Philippines, 4025
                    </h6>
                  </div>
                </li>
                <li class="listed-info">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-phone icons"></i>
                    <h6 class="mb-0">0917 111 8008</h6>
                  </div>
                </li>
                <li class="listed-info">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-envelope icons"></i>
                    <h6 class=" mb-0">support@tawitech.ph</h6>
                  </div>
                </li>
                <li class="listed-info">
                  <div class="social-media d-flex">
                    <a
                      href="https://www.youtube.com/channel/UC0QzEnq-E5kQLFveXynVdUg"
                      target="_blank"
                      ><i class="fa fa-youtube-play"></i
                    ></a>
                    <a href="https://www.instagram.com/ayun_ph/" target="_blank"
                      ><i class="fa fa-instagram"></i
                    ></a>
                    <a href="https://www.facebook.com/ayunph" target="_blank"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </div>
                </li>
                <li class="listed-info">
                  <div class="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10938.27919499842!2d121.09744819571154!3d14.237716858398741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33bd628962e5f129%3A0x93fc061e591a8ab6!2sTAWI%20Group%20of%20Companies!5e0!3m2!1sen!2sph!4v1642992035963!5m2!1sen!2sph"
                      class="google-map"
                      allowfullscreen=""
                      loading="lazy"
                    >
                    </iframe>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import api from "../../api/contact-us";

export default {
  name: "contact-us",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        companyName: "",
        fullName: "",
        email: "",
        message: "",
        dateCreated: Date.now(),
      },
    };
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.$toast.warning("Please address the field/s with invalid input.");
        return;
      } else if (this.form.email === "") {
        this.$toast.warning("Email is required.", "Please complete the fields");
        return;
      }

      try {
        this.isLoading = true;
        const send = await api.sendCustomerInquiry(this.form);

        this.$toast.success(
          "Please check your email regularly.",
          "Your message has been sent!"
        );

        this.$refs.form.reset();
        this.resetForm();
      } catch (error) {
        this.$toast.error("Something went wrong, please try again.", "Oops!");
      }

      this.isLoading = false;
    },
    resetForm() {
      this.form = {
        companyName: "",
        fullName: "",
        email: "",
        message: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  max-width: 950px;
  margin: 0 auto;
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
  border-radius: 10px;
  padding: 60px;
  box-sizing: border-box;
}

.icons {
  width: 20px;
  text-align: center;
  margin-right: 19px;
  font-size: 20px;
  color: #f18f01;
}

.social-media {
  a {
    color: #f18f01;
    font-size: 20px;
    margin-right: 20px;
  }
}

.listed-info {
  padding: 11px 0;
}
.google-map {
  border: 0;
  width: 100%;
  max-width: 340px;
  height: 340px;
}
.btn-elog-primary {
  background-color: #122b91;
  color: #fff;
}

.get-in-touch {
  position: relative;
  font-weight: bold;
  font-size: 30px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #122c91;
  padding-bottom: 10px;
  &::after {
    content: "";
    width: 160px;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #f18f01;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 25px !important;
    &::after {
      width: 100px !important;
    }
  }
}
@media only screen and (max-width: 860px) and (min-width: 250px) {
  .google-map {
    height: 160px;
  }
  .map {
    height: 90px;
  }
  h3 {
    font-size: 20px !important;
  }
}
</style>
