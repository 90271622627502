<template>
  <section class="section bg-secondary">
    <div class="container-fluid">
      <div class="row justify-content-center text-center mb-sm">
        <div class="col-lg-12">
          <h3 class="display-3">Blogs</h3>
          <p class="lead">Know more about us</p>
        </div>
      </div>

      <div class="container-fluid blogs-container">
        <div class="row">
          <div class="col-md-1 align-self-center">
            <base-button
              type="white"
              class="blog-navigator text-ayun-primary"
              @click="swipeLeft"
            >
              <i class="fa fa-chevron-left"></i>
            </base-button>
          </div>
          <div class="col-md-10">
            <perfect-scrollbar id="content">
              <div class="row flex-nowrap pb-3">
                <div
                  class="col-sm-7 col-md-6 col-lg-5 col-xl-4"
                  v-for="(item, index) in getAllBlogs"
                  :key="index"
                >
                  <card class="card-blog shadow-sm border-0" body-classes="p-0">
                    <div class="image-container d-flex align-items-center">
                      <img
                        v-lazy="item.image.url"
                        class="img-fluid blog-image"
                      />
                    </div>

                    <blockquote class="card-blockquote">
                      <h4 class="font-weight-bold">{{ item.title }}</h4>
                      <div class="blog-description">
                        <p class="lead crop-text">{{ item.description }}</p>
                      </div>
                      <router-link :to="`/blog/${item.id}`">
                        See More
                      </router-link>
                    </blockquote>
                  </card>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="col-md-1 align-self-center">
            <base-button
              type="white"
              class="blog-navigator text-ayun-primary"
              @click="swipeRight"
            >
              <i class="fa fa-chevron-right"></i>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Blogs",
  created() {
    this.$store.dispatch("blogs/fetchAllAction");
  },
  computed: mapGetters("blogs", ["getAllBlogs"]),
  methods: {
    goToBlog(blogId) {
      this.$router.push(`/blog/${blogId}`);
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;

      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;

          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);

          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;

          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }

        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const content = document.querySelector("#content");
      this.scrollTo(content, -300, 500);
    },
    swipeRight() {
      const content = document.querySelector("#content");
      this.scrollTo(content, 300, 500);
    },
  },
};
</script>

<style lang="scss">
.blog-description {
  min-height: 280px;
  max-height: 280px;
  padding: 0 0 10px 0;
}

.crop-text {
  -webkit-line-clamp: 8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.blog-image {
  margin: auto;
}

.blogs-container {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.image-container {
  min-height: 250px;
  max-height: 250px;
}

.card-blog {
  width: 20rem;
}

@media (max-width: 575.98px) {
  .blogs-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-blog {
    width: 20rem;
  }

  .blog-navigator {
    visibility: hidden;
  }
}

@media (max-width: 767.98px) {
  .blogs-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-blog {
    width: 20rem;
  }

  .blog-navigator {
    visibility: hidden;
  }
}

@media (max-width: 991.98px) {
  .blogs-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-blog {
    width: 20rem;
  }
}
</style>