<template>
  <section class="section section-lg">
    <div class="container">
        <div class="row justify-content-center text-center mb-sm">
            <h2 class="display-3 after">How it Works</h2>
          </div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="card text-center" style="width: 18rem;">
                    <img src="../../assets/images/optimized/tag.svg" alt="Card image cap" style="height:150px">
                    <div class="card-body d-flex flex-column">
                        <h4 class="card-title">Tag</h4>
           
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="card text-center" style="width: 18rem;">
                    <img src="../../assets/images/optimized/scan.svg" alt="Card image cap" style="height:150px">
                    <div class="card-body d-flex flex-column">
                        <h4 class="card-title">Scan</h4>
                 
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="card text-center" style="width: 18rem;">
                    <img src="../../assets/images/optimized/track.svg" alt="Card image cap" style="height:150px">
                    <div class="card-body d-flex flex-column">
                        <h4 class="card-title">Track</h4>
             
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "how-it-works"
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bolder;
  font-size: 40px;
  position: relative;
  padding: 10px 0 10px;
  margin: 0 0 50px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 160px;
    width: 90%;
    height: 2px;
    background: #F18F01;
  }
}

.card {
    border: none;
    margin: 85px auto 0;
    img {
        margin: 0 auto;
    }

}
@media (max-width: 768px) {
    h2{
        font-size: 25px !important;
        &::after {
            max-width: 100px;
        }
    }
    h4{
        font-size: 20px;
    }
    img{
        height: 80px !important;
        margin-bottom: -20px !important;
    }
}
@media screen and (max-width:990px) {
    .card { margin: 20px auto 0; }
}
</style>
