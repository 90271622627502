import axios from 'axios';
import config from '@/config/env.constants';

export default {
  baseUrl: 'https://us-central1-ares-' + config.currEnv + '-app.cloudfunctions.net',

  sendCustomerInquiry(params) {
    const url = `${this.baseUrl}/sendCustomerInquiry`;
    return axios.post(url, params);
  }
}