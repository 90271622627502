<template>
  <section class="section">
    <div class="container">
      <div
        class="
          row
          flex-column
          justify-content-center
          align-items-center
          text-center
          mb-2
        "
      >
        <loading
          :active.sync="isLoading"
          loader="spinner"
          color="#20A8D8"
          :is-full-page="false"
        />

        <div class="col-lg-6">
          <h2 class="display-3">How can we help you today?</h2>
        </div>

        <div class="col-lg-6">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter your search name here"
              aria-label="Ask a question"
              aria-describedby="button-addon2"
              v-model="question"
              v-on:keyup="search"
            />
          </div>
        </div>

        <div
          v-if="!isTyping && searchedQuestions.length === 0"
          class="col-lg-6"
        >
          <div class="col-md-12 align-items-center text-left px-0">
            <button class="btn plus-button" @click="sendQuestion">&#43;</button>
            <span>New Support Ticket</span>
          </div>
        </div>
      </div>

      <div class="row" v-if="searchedQuestions.length > 0">
        <div class="col-12 mt-2 questions">
          <h3 class="mb-4">FAQs</h3>
        </div>

        <div
          class="col-6 mt-2 mb-4 questions"
          v-for="(item, index) in searchedQuestions"
          :key="index"
        >
          <article>
            <h6 class="title">{{ item.question }}</h6>
            <p class="content">
              <span v-html="item.answer" />
            </p>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Faqs",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      isTyping: false,
      question: "",
    };
  },
  computed: {
    ...mapGetters("faqs", ["searchedQuestions"]),
  },
  watch: {
    question(value) {
      if (!value || value == null || value == "") {
        this.$store.commit("faqs/TYPE_QUESTION", value);
      }

      this.isTyping = true;
    },
  },
  created() {
    this.$store.dispatch("faqs/fetchAllAction");
  },
  methods: {
    openNewTab(url) {
      window.open(url, "_blank");
    },
    downloadApp() {
      let url = `https://play.google.com/store/apps/details?id=com.tawitech.ayun`;
      this.openNewTab(url);
    },
    downloadAppGallery() {
      let url = `https://appgallery.huawei.com/#/app/C103708333`;
      this.openNewTab(url);
    },
    sendQuestion() {
      let url = `https://newaccount1638702762632.freshdesk.com/support/tickets/new`;
      this.openNewTab(url);
    },
    search() {
      this.isTyping = false;

      this.$store.commit("faqs/TYPE_QUESTION", this.question);
      this.$gtag.event("SEARCH", {
        event_category: "FREQUENTLY_ASKED_QUESTION",
        event_label: this.question,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 24px;
  position: relative;
  padding: 10px 0 10px;
  margin: 0 0 50px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 160px;
    width: 90%;
    height: 2px;
    background: #F18F01;
  }
}

h1 {
  font-size: 36px;
  font-weight: 500;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  padding: 0 0 8px;
  position: relative;
  color: #122b91;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 2px;
    background: #F18F01;
  }
}
h6{
  font-size: 16px !important;
  color: #F18F01 !important;
  font-weight: bold !important;
}
p{
  font-size: 14px !important;
  text-align: justify;
}
.card {
  border: none;
}

.plus-button {
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  background: #296ddb;
  color: #fff;
}

.questions {
  article {
    max-width: 350px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #091133;
    }
    .content {
      color: #5d6970;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
    }
  }
}
</style>