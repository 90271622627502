<template>
  <section class="section section-lg section-shaped my-0">
    <div class="container-fluid">
      <div class="row justify-content-center text-center mb-sm">
        <div class="col-md-6 first">
          <h3 class="display-3 after">Ayun! Asset-Based</h3>
          <p style="margin-top: 20px;">
            Automate the tracking of your assets through Ayun! Asset-Based, an
            asset monitoring system that uses QR CODES and CLOUD TECHNOLOGY to
            transfer and track the accountability of Returnable Packaging
            Equipment (RPE) within the supply chain. Ayun! AB has fixed asset
            monitoring in multiple locations, both online and offline.
          </p>
          <p style="color:#F18F01; font-weight:bold; margin-top:20px">
            Where do we recommend Ayun! Asset-Based?
          </p>
          <p style="margin-top:-20px">
            Pharmaceuticals, Pawnshop and Remittances, Logistics, Health Care,
            Retails, Hospitality, Construction, Education, Government,
            Agriculture and Livestock, Food and Beverages, Banks, Microfinance,
            Distributors, and Manufacturing Companies.
          </p>
        </div>
        <div class="col-md-6 second">
          <img
            src="../../assets/images/optimized/ayun-asset-based-screens.svg"
            alt="Ayun! Asset-Based"
            class="title-image"
          />
        </div>
      </div>
      <div class="inverse features-bg ">
        <h5 class="display-3 after">App Features</h5>
        <div class="d-flex flex-wrap justify-content-between pt-md-5">
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/QR-code-and-Barcode-Scanning.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">QR Code and Barcode Scanning</h4>
            <h6>
              Scanning swiftly of tagged assets to save more time in
              inventory-taking.
            </h6>
          </div>
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/Inventory-Management-Tab.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">Dispatch-Receipt Management</h4>
            <h6>
              Stay organized and on top of your game by checking and tracking
              your inventories through the use of the Inventory Management Tab.
            </h6>
          </div>
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/Offline-Mode.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">Offline Mode</h4>
            <h6>
              No internet connection? We've got you! Enjoy the seamless creation
              of inventories even if you don't have the internet. Just don't
              forget to sync it online before you log out!
            </h6>
          </div>
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/User-Visibility-Restrictions.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">User Visibility Restrictions</h4>
            <h6>
              Are there assets or transactions that you need to specifically
              have access to? No worries; every user can modify the visibility
              of data.
            </h6>
          </div>
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/Downloadable-Reports.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">Downloadable Reports</h4>
            <h6>
              Tired of doing spreadsheets? Do you usually have trouble inputting
              crucial information? Sometimes it can be incorrect too! But hey,
              all you need to do is click the download button. It is an easier
              life.
            </h6>
          </div>
          <div class="col-md-4 features">
            <img
              src="../../assets/images/features/Email-Notifications.svg"
              alt="QR-code-and-Barcode-Scanning"
            />
            <h4 class="py-3 mb-0">Notifications</h4>
            <h6>
              You were so busy that you forgot to monitor or check inventories!
              We can enable notifications as a reminder.
            </h6>
          </div>
        </div>
      </div>
      <div class="row justify-content-center text-center mb-sm">
        <h5 class="display-3 after">Ayun! AB Products</h5>
      </div>
      <div class="row justify-content-center mb-md">
        <div class="col-sm-12 text-center col-md-6">
          <img
            src="../../assets/images/optimized/ayun-inventory.svg"
            alt="Phone Screen"
            class="phone"
          />
        </div>
        <div id="ab-inventory" class="col-md-6">
          <h2 class="display-3 after">Ayun! AB Inventory</h2>
          <p>
            Ayun! AB Inventory helps you manage your business inventory and keep
            track of exactly where your assets are. Save more time in
            inventory-taking by swiftly scanning QR Codes and Barcodes.
          </p>
          <div class="links d-flex justify-content-center">
            <img
              src="../../assets/images/optimized/Playstore_Button.svg"
              class="downloadable-link"
              style="margin-right:30px"
              @click="downloadApp"
            />
            <img
              src="../../assets/images/optimized/AppGallery_Button.svg"
              class="downloadable-link"
              @click="downloadAppGallery"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-md">
        <div id="ab-dispatch" class="col-md-6 third">
          <h2 class="display-3 after">Ayun! AB Dispatch</h2>
          <p>
            Automate the tracking of your assets through Ayun!, an asset
            monitoring system that uses QR CODES and CLOUD TECHNOLOGY to
            transfer and track the accountability of Returnable Packaging
            Equipment (RPE) within the supply chain.
          </p>
          <div class="links d-flex justify-content-center">
            <img
              src="../../assets/images/optimized/Playstore_Button.svg"
              class="downloadable-link"
              style="margin-right:30px"
              @click="downloadApp"
            />
            <img
              src="../../assets/images/optimized/AppGallery_Button.svg"
              class="downloadable-link"
              @click="downloadAppGallery"
            />
          </div>
        </div>
        <div class="col-sm-12 text-center col-md-6 fourth">
          <img
            src="../../assets/images/optimized/ayun-dispatch.svg"
            alt="Phone Screen"
            class="phone"
          />
        </div>
      </div>
      <br /><br />
      <div class="row align-items-center">
        <div class="col-sm-12 text-center col-md-6">
          <img
            src="../../assets/images/optimized/ayun-sticker-printer.svg"
            alt="Phone Screen"
            class="phone"
          />
        </div>
        <div id="ab-sticker-printer" class="col-md-6">
          <h2 class="display-3 after">Ayun! AB Sticker Printer</h2>
          <p>
            Ayun! Sticker Printer enables you to print in a wide variety of
            locations, as no PC workstation is needed. Having a printed QR code
            right away can potentially help workers complete tasks in a shorter
            timeframe.
          </p>
          <div class="links d-flex justify-content-center">
            <img
              src="../../assets/images/optimized/Playstore_Button.svg"
              class="downloadable-link"
              style="margin-right:30px"
              @click="downloadApp"
            />
            <img
              src="../../assets/images/optimized/AppGallery_Button.svg"
              class="downloadable-link"
              @click="downloadAppGallery"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ayun-asset-based",
  methods: {
    downloadApp() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.tawitech.ayun", // change this soon
        "_blank"
      );
    },
    downloadAppGallery() {
      window.open(
        "https://appgallery.huawei.com/#/app/C103708333", // change this soon
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  background-color: #eaf1fc;
  padding: 50px;

  h5 {
    font-size: 40px;
    padding-top: 100px;
    text-align: center;
  }
  h4 {
    font-size: 20px;
    color: #f18f01;
  }
  h3 {
    font-weight: bolder;
    font-size: 45px;
    position: relative;
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 160px;
      width: 90%;
      height: 2px;
      background: #f18f01;
    }
  }
}
h2 {
  color: #f18f01 !important;
  font-weight: bolder;
  font-size: 35px;
  position: relative;
  padding: 60px 0 0 10px;
  text-align: center;
}
p {
  font-size: 14px;
  text-align: justify;
}
h6 {
  font-size: 14px;
  text-align: justify;
}
.title-image {
  width: 100%;
}
.phone {
  width: 80%;
}
.downloadable-link {
  width: 160px;
}
.features-bg {
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(0 -100vmax);
  padding-bottom: 100px;
}
.inverse {
  background-color: white;
}
@media (max-width: 768px) {
  .first {
    order: 2;
  }
  .second {
    order: 1;
  }
  .third {
    order: 4;
  }
  .fourth {
    order: 3;
  }
}
h3 {
  font-size: 25px !important;
  padding-bottom: 10px;
  &::after {
    max-width: 110px !important;
  }
}
p {
  margin: 20px;
}
.title-image {
  width: 80%;
}
h5 {
  font-size: 25px;
}
h4 {
  font-size: 16px !important;
}
h2 {
  font-size: 40px;
}
h6 {
  margin: -20px 0 20px 0;
}
p {
  margin-top: 20px;
}
.phone {
  width: 60%;
}
.downloadable-link {
  width: 140px;
}
@media only screen and (max-width: 990px) {
  h2 {
    font-size: 25px !important;
  }
  .phone {
    margin-top: 80px;
    width: 50%;
  }
  .downloadable-link {
    width: 130px;
  }
}
@media only screen and (max-width: 750px) and (min-width: 250px) {
  .phone {
    width: 70%;
  }
}
</style>
